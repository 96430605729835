<template>
  <v-dialog
    :value="dialog"
    persistent
    :width="dialogWidth"
    :fullscreen="dialogFullscreen"
  >
    <base-material-card
      :color="dialogType"
    >
      <template v-slot:heading>
        <div
          class="display-2 font-weight-bold"
        >
          {{ title }}
        </div>
        <div>
          {{ environment }}
        </div>
      </template>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="onDialogClose"
        >
          {{ this.$t('buttonClose') }}
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'ErrorDialogComponent',
    props: {
      dialogType: {
        type: String,
        default: 'error',
      },
      dialog: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
    },
    data: function () {
      return {
        environment: process.env.VUE_APP_ENVIRONMENT,
        dialogWidth: '35%',
        dialogFullscreen: false,
      }
    },
    created: function () {
      // based on the device type control the dialog width
      if (this.$device.mobile) {
        this.dialogFullscreen = true
      } else if (this.$device.ipad) {
        this.dialogFullscreen = false
        this.dialogWidth = '50%'
      } else {
        this.dialogFullscreen = false
        this.dialogWidth = '35%'
      }
    },
    methods: {
      onDialogClose: function () {
        this.$emit('update:dialog', false)
      },
    },
  }
</script>
